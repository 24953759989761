import { Component, OnInit } from "@angular/core";
//import { ClassService } from '../../class.service';
import { HttpHeaders, HttpClient } from "@angular/common/http";
declare var $: any;
import * as moment from "moment";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
const todaysDate = moment().format("MM/DD/YYYY");
const endDate = moment()
  .add(1, "months")
  .format("MM/DD/YYYY");
@Component({
  selector: "app-schedule",
  templateUrl: "./schedule.component.html",
  styleUrls: ["./schedule.component.css"],
})
export class ScheduleComponent {
  classevents: any[];
  isdataavailable: any;
  isLoading: boolean = true;
  cview: string;
  bsValue: any = new Date();
  bsConfig: Partial<BsDatepickerConfig>;
  // constructor(private classService: ClassService){}
  constructor(private http: HttpClient) { }
  title = "Juma fullcalendar";
  apiURL = "https://app.jumafit.com/getclass";

  ngOnInit() {
    if (window.innerWidth >= 768) {
      var calenderView = "basicWeek";
    } else {
      var calenderView = "basicDay";
    }
    this.cview = calenderView;
    this.bsConfig = {
      isAnimated: true,
      containerClass: "theme-dark-blue",
      showWeekNumbers: false,
      minDate: new Date(),
    };
    this.isdataavailable = false;
    //console.log("apiURLapiURLapiURL",this.apiURL)
    this.classevents = [];
    this.http.get(`${this.apiURL}`).subscribe((responsedata) => {
      //console.log("responsedata",responsedata)

      responsedata["Classes"].map((item) => {
        if (item.IsAvailable && !item.IsCanceled) {
          var classLink = `<a target="_blank" href="https://cart.mindbodyonline.com/sites/59430/cart/add_booking?item[info]=${item.StartDateTime}&item[mbo_id]=${item.Id}&item[mbo_location_id]=${item.Location.Id}&item[name]=${item.ClassDescription.Name}&item[type]=${item.ClassDescription.Program.ScheduleType}" role="button"> Book</a>`;
          this.classevents.push({
            title: "<b>" + item.ClassDescription.Name + "</b>",
            start: moment(item.StartDateTime).format("YYYY-MM-DD HH:mm"),
            starttime:
              moment(item.StartDateTime).format("h:mm a") +
              " - " +
              moment(item.EndDateTime).format("h:mm a") +
              " EST",
            color: randomcolor[Math.floor(Math.random() * randomcolor.length)],
            name: item.Staff.FirstName + " " + item.Staff.LastName,
            description: item.ClassDescription.Description,
            classlink: classLink,
          });
        }
      });
    });
    var randomcolor = ["#f9c66a", "#019efb"];

    // this.classService.listClass().subscribe((res)=>{
    //   console.log(res);
    // });
    setTimeout(() => {
      this.isdataavailable = true;
      //console.log("this.classevents",this.classevents)
      $("#calendar").fullCalendar({
        header: {
          left: "prev",
          center: "title",
          right: "next",
        },
        defaultView: calenderView,
        navLinks: false,
        editable: false,
        eventLimit: false,
        allDaySlot: false,
        displayEventTime: false,
        slotEventOverlap: false,
        views: {
          week: {
            columnFormat: "MMM DD ddd", // set format for week here
          },
        },
        buttonText: {
          prev: "<",
          next: ">",
        },
        events: this.classevents,
        viewRender: (view) => {
          this.bsValue = new Date(view.intervalStart);
        },
        textEscape: false,
        eventRender: function (event, element) {
          element.css("font-size", "1.2em");
          element.css("padding", "10px");
          element.css("white-space", "normal");
          // event.find('.fc-title').innerHTML = "<i>" + event.title + "</i>";
          //element.find(".fc-title").prepend("<h1>"+event.title+"</h1>");
          element.find(".fc-title").remove();
          var new_description = `
              <div class="event-details">
                <div>
                  <div>
                    ${event.title}<br/>
                    ${event.starttime}<br/>
                    ${event.name}<br/>
                  </div>
                  ${calenderView === "basicDay" && event.description ? `
                    <a href="#" class="view-more-btn" role="button">
                      <i class="fa fa-angle-right"></i> View details
                    </a>
                  ` : ''}
                </div>
                ${calenderView === "basicDay" ? `
                  <div class="book-now-btn btn ${event.color === "#f9c66a" ? "btn-default" : "btn-primary"} btn-sm">
                    ${event.classlink}
                  </div>
                ` : ''}
              </div>
              <div class="event-description" style="display: none;">
                <div class="col-md-12">
                  ${event.description}
                </div>
              </div>
            `;

          element.find(".fc-content").append(new_description);

          if (calenderView === "basicDay" && event.description) {
            element.find(".view-more-btn").on("click", function (e) {
              e.preventDefault();

              var details = $(this).closest(".event-details").next(".event-description");
              var isVisible = details.is(":visible");

              details.toggle();

              if (isVisible) {
                $(this).html('<i class="fa fa-angle-right"></i> View details');
              } else {
                $(this).html('<i class="fa fa-angle-down"></i> Hide details');
              }
            });
          }

          if (calenderView === "basicWeek") {
            element
              .popover({
                title: event.title,
                placement: "bottom",
                html: true,
                animation: false,
                container: "body",
                content: function () {
                  return `<div class="row">
                                    <div class="col-md-12">
                                      <h3>${event.starttime}</h3>
                                      <p>${event.description}</p>
                                      <p>${event.classlink}</p>
                                      
                                    </div>
                                  </div>`;
                },
                trigger: "manual",
              })
              .on("mouseenter", function () {
                var _this = this;
                $(this).popover("show");
                $(".popover").on("mouseleave", function () {
                  $(_this).popover("hide");
                });
              })
              .on("mouseleave", function () {
                var _this = this;
                //setTimeout(function () {
                if (!$(".popover:hover").length) {
                  $(_this).popover("hide");
                }
                // }, 300);
              });
          }
        },
      });
      this.isLoading = false;
    }, 5000);
    dayHeaderContent: (args) => {
      return moment(args.date).format("ddd Do");
    };
  }

  onDateChange(newDate: Date): void {
    $("#calendar").fullCalendar("gotoDate", moment(newDate).format("YYYY-MM-DD"));
  }
}
